<template>
  <v-container class="grey lighten-5">

    <v-row
      justify="center"
      align="center">
      <v-col
        cols="12"
        align="center">
          <img width="120px" src="@/assets/images/logo.png">
          <h1>404</h1>
          <h3>{{$i18n.t('NotFound')}}</h3>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
export default {
}
</script>